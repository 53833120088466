import Head from 'next/head'
import Layout from '../components/layout'
import HOME_PAGE from '../lib/queries/home-page'
import { HomePageRecord } from '../generated/graphql'
import { renderMetaTags, useQuerySubscription } from 'react-datocms'
import SubscribedQuery from '../types/SubscribedQuery'
import Blocks from '../blocks/blocks'
import subscriptionQuery from '../utils/subscription-query'
import MainMenu from '../blocks/main-menu'
import Footer from '../blocks/footer/footer-block-record'
import { ScrollTrigger } from '../lib/gsap'
import useIsomorphicLayoutEffect from '../hooks/use-isomorphic-layout-effect'

export async function getStaticProps({ params, preview }: any) {
  return subscriptionQuery(HOME_PAGE, preview)
}

function Home(
  props: SubscribedQuery<{
    homePage: HomePageRecord
  }>
) {
  const { data, error } = useQuerySubscription(props.subscription)

  useIsomorphicLayoutEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh()
    }, 2000)
  }, [])

  if (!data?.homePage) return null
  const { mainMenuComponent, contentLayout, footer, ...colorConfig } =
    data.homePage

  return (
    <Layout
      backgroundColor={data?.homePage.backgroundColorHex!}
      textColor={data?.homePage.textColorHex!}
      preview={props.subscription.preview}
    >
      <div>
        <Head>
          {renderMetaTags(data!.homePage._seoMetaTags)}
          <link rel="icon" href="/public/favicon.ico" />
        </Head>

        <>
          {error && (
            <div>
              {error.code} - {error.message}
            </div>
          )}
          {mainMenuComponent && (
            <MainMenu colorConfig={colorConfig} block={mainMenuComponent} />
          )}
          <Blocks blocks={data?.homePage.contentLayout ?? []} />
          {footer && (
            <Footer
              footer={footer}
              backgroundColorHex={footer.backgroundColorHex!}
            />
          )}
        </>
      </div>
    </Layout>
  )
}

export default Home
